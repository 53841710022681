<script type="text/javascript">
    // 定义一些公共的属性和方法
//阿里云

     const http = 'https://api10.dongchenpay.net';
     const ht =   'http://jrttgj.dongchenpay.net';
     const fmht =  'http://jrttgj.dongchenpay.net/list';
     const xzht = 'http://jrttgj.dongchenpay.net';
     const xfht = 'http://jrttgj.dongchenpay.net';
     const syt = 'http://jrttgj.dongchenpay.net';
     const https = true;


//北京
/*
const http = 'https://api10.dongchenpay.net';
const ht = 'http://jrttgj.dongchenpay.net';
const  fmht = 'http://jrttgj.dongchenpay.net/list';
const xzht = 'http://jrttgj.dongchenpay.net';
const xfht = 'http://jrttgj.dongchenpay.net';
const syt = 'http://jrttgj.dongchenpay.net';
const https = true;
*/
/*
const http = 'https://api10.dongchenpay.net';
 const ht = 'http://jrttgj.dongchenpay.net';
const  fmht = 'http://jrttgj.dongchenpay.net';
 const xzht = 'http://jrttgj.dongchenpay.net';
 const xfht = 'http://jrttgj.dongchenpay.net';
const syt = 'http://jrttgj.dongchenpay.net';
 const https = true;
*/





 //测试

/*
 const http = 'https://api10.dongchenpay.net';
 const ht =   'http://jrttgj.dongchenpay.net';
 const fmht = 'http://jrttgj.dongchenpay.net';
 const xzht = 'http://jrttgj.dongchenpay.net';
 const xfht = 'http://jrttgj.dongchenpay.net';
 const  syt = 'http://jrttgj.dongchenpay.net';
 const https = true;
*/
//https


/*
     const http = 'http://jrttgj.dongchenpay.net';
     const ht =   'http://jrttgj.dongchenpay.net';
     const fmht =  'http://jrttgj.dongchenpay.net';
     const xzht = 'http://jrttgj.dongchenpay.net';
     const xfht = 'http://jrttgj.dongchenpay.net'; 
     const syt = 'http://jrttgj.dongchenpay.net';
      const https = true;
*/


//https2

/*
     const http = 'https://api10.dongchenpay.net';
     const ht =   'http://jrttgj.dongchenpay.net';
     const fmht =  'http://jrttgj.dongchenpay.net';
     const xzht = 'http://jrttgj.dongchenpay.net';
     const xfht = 'http://jrttgj.dongchenpay.net';
      const https = true;
*/

     const ym_arr=['jrttgj.dongchenpay.net','jrttgj.dongchenpay.net','jrttgj.dongchenpay.net'];

    function commonFun() {
      
    }
    // 暴露出这些属性和方法
    export default {
        http,
        ht,
        fmht,
        xzht,
        xfht,
        commonFun,
        https,
        ym_arr,
        syt
    }
</script>
